import React from "react"

import { css, SerializedStyles } from "@emotion/react"
import styled from "@emotion/styled"

import { typographyStyles, colours } from "../index"
import { mixins } from "../mixins"
import { Target } from "../types"

interface TextLinkStylesProps {
  disabled: boolean
  colour?: "blue" | "black" | "white"
}

export const textLinkStyles = ({
  disabled = false,
  colour = "blue",
}: Partial<TextLinkStylesProps>): SerializedStyles => css`
  display: inline;
  ${typographyStyles.bodyBold};
  font-size: inherit;
  border-radius: 4px;
  text-decoration: underline;
  color: ${colour === "black"
    ? colours.offBlack
    : colour === "white"
    ? colours.white
    : colours.action.main};

  ${disabled
    ? `
    opacity: 0.5;
    pointer-events: none;
  `
    : ``}

  &:hover {
    text-decoration: none;
  }

  &:focus-visible {
    ${mixins.focused};
  }
`

const TextLinkInternal = styled.a<Partial<TextLinkStylesProps>>`
  ${props => textLinkStyles(props)}
`

interface Props {
  text: string
  href: string
  target?: Target
  rel?: string
  onClick?: React.MouseEventHandler<HTMLAnchorElement>
  className?: string
}

export const TextLink: React.FunctionComponent<
  Props & Partial<TextLinkStylesProps>
> = ({ text, href, target, rel, disabled, colour, onClick, className }) => (
  <TextLinkInternal
    href={disabled ? undefined : href}
    // https://www.scottohara.me//blog/2021/05/28/disabled-links.html
    aria-disabled={disabled}
    role={disabled ? "link" : undefined}
    target={target}
    rel={rel}
    disabled={disabled}
    colour={colour}
    onClick={onClick}
    className={className}
  >
    {text}
  </TextLinkInternal>
)
